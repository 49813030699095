const apiRequest = require("./utils/api_request")
const $ = require('jquery');
const naturalCompare = require('string-natural-compare');
const id = require('./utils/ID.js');


/**
 * Modulo que maneja los origenes de datos.
 * @module dataSource
 */


/**
 * Maneja origines de datos.
 * Los datos se pueden pedir a una url o pueden ser dados directamente en un array.
 * 
 * @constructor
 * @param {module:dataSource~DataSourceDef} ds objecto de configuración
 */
let dataSource = function (ds) {
    let self = this;
    let _mustReFetch = true;
    let _cache = new Promise((resolve, reject) => resolve([]));
    self._dict = {};

    /**
     * @typedef {Object} DataSourceDef
     * 
     * Tiene que estar definida la url desde donde buscar los datos o un array.
     * 
     * @property {string} url - Url del datasource
     * @property {boolean} idInURL - Si al hacer un put o un delete tenemos que pasarle el id en la url (default true).
     * @property {string} keyColumn - Columna de identificacion
     * @property {string} displayColumn - Columna a mostrar
     * @property {module:tablas~CalculateDisplayValueCb} calculateDisplayValue - Funcion que se utiliza para calcular el valor a mostrar.
     * @property {Array<Object>} items - Array de objetos (origen de datos)
     */
    let _ds = $.extend(true, {
        url: null,
        params: {},
        keyColumn: "id",
        items: [],
        displayColumn: 'name',
        calculateDisplayValue: null,
        idInURL: true,
    }, ds);

    if (_ds.url == null) {
        _ds.items.sort((a, b) => {
            if (_ds.calculateDisplayValue) {
                data_a = _ds.calculateDisplayValue(a);
                data_b = _ds.calculateDisplayValue(b);
                if (!data_a && !data_b) return 0;
                if (!data_a) return -1;
                if (!data_b) return 1;
                return naturalCompare(data_a, data_b, { caseInsensitive: true });
            }
            return naturalCompare(a[_ds.displayColumn], b[_ds.displayColumn], { caseInsensitive: true });
        });
        self._dict = {};
        for (const item of _ds.items) {
            self._dict[item[_ds.keyColumn]] = item;
        }
    }

    self.keyColumn = _ds.keyColumn;
    self.displayColumn = _ds.displayColumn;
    self.calculateDisplayValue = _ds.calculateDisplayValue;

    self.updateItems = function (newItems) {
        self._dict = {};
        for (const item of newItems) {
            self._dict[item[self.keyColumn]] = item;
        }
    }

    self.getItems = async function (extraParams) {
        if (_ds.url == null) {
            return Object.values(self._dict);
        } else if (_mustReFetch || extraParams) {
            let params = new URLSearchParams(_ds.params);
            if (extraParams) {
                for (const x of extraParams.entries()) {
                    params.append(x[0], x[1]);
                }
            }

            let res = await apiRequest("GET", _ds.url, params.toString());
            _mustReFetch = false;
            self._dict = {};
            let items = res;
            if ('data' in res) items = res.data;
            for (const item of items) {
                self._dict[item[_ds.keyColumn]] = item;
            }
            return res;
        } else {
            return Object.values(self._dict);
        }
    }

    self.getItemByKeyColumn = async function (id) {
        await self.getItems();
        return self._dict[id];
    }

    self.getItemByKeyColumnSync = function (id) {
        return self._dict[id];
    }

    self.getAllItemsSync = function (id) {
        return Object.values(self._dict);
    }

    self.reload = function () {
        _mustReFetch = true;
    }

    self.updateParams = function (newParams) {
        $.extend(_ds.params, newParams);
        _mustReFetch = true;
    }

    self.updateURL = function (newURL) {
        _ds.url = newURL;
        _mustReFetch = true;
    }

    /**
     * Guarda el objeto:
     * 
     * Si la fuente de datos es remota (tiene url) entonces hacemos un post o un put, dependiendo si tiene o no id.
     * Si la fuente es una array se guarda en el mismo.
     * 
     * @param {Object} obj 
     */
    this.save = async function (obj) {
        if (_ds.url) {
            let method = 'POST';
            if (obj.id) {
                method = 'PUT';
            }
            let url = _ds.url;
            if (_ds.idInURL && obj.id) {
                url = `${_ds.url}/${obj.id}`
            }
            return await apiRequest(method, url, obj).then((obj) => {
                self._dict[obj.id] = obj;
                return obj;
            });
        }
        // nuestro datasource es un array
        if (!obj.id) {
            obj.id = id.ID();
            self._dict[obj.id] = obj;
        } else {
            self._dict[obj.id] = $.extend(self._dict[obj.id], obj);
        }
        return self._dict[obj.id];
    }

    /**
     * Borra el objeto con el id dado del datasource
     * 
     * @param {int | string} id 
     */
    this.delete = async function (id) {
        if (_ds.url == null) {
            delete self._dict[id];
            return true;
        }
        let url = _ds.url;
        if (_ds.idInURL) {
            url = `${_ds.url}/${id}`;
        }
        return apiRequest('DELETE', url, {
            id
        }).then(() => {
            delete self._dict[id];
        });
    }

    return this;
}

module.exports = dataSource