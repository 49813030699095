/**
* Generate unique IDs for use as pseudo-private/protected names.
* Similar in concept to
* [Doku]{@link https://web.archive.org/web/20160317043650/http://wiki.ecmascript.org/doku.php?id=strawman:names}
* 
* The goals of this function are twofold:
* 
* * Provide a way to generate a string guaranteed to be unique when compared
*   to other strings generated by this function.
* * Make the string complex enough that it is highly unlikely to be
*   accidentally duplicated by hand (this is key if you're using `ID`
*   as a private/protected name on an object).
* Use:
* ```
*     var privateName = ID();
*     var o = { 'public': 'foo' };
*     o[privateName] = 'bar';
* ```
* [source]{@link https://gist.github.com/gordonbrander/2230317}
*  @module ID
*/

let ID = function () {
    // Math.random should be unique because of its seeding algorithm.
    // Convert it to base 36 (numbers + letters), and grab the first 9 characters
    // after the decimal.
    return '_' + Math.random().toString(36).substring(2, 11);
};

module.exports = {ID};
