const api_request = require("./api_request");

/**
 * Funciones utilitarias para descargar y subir archivos.
 * @module files
 */

module.exports = {

    /**
     * download descarga en el navegador el array de bytes que se le pasa como argumento en un archivo (con el nombre dado)
     * 
     * @param {string} resultByte contenido del archivo
     * @param {string} fname nombre que el navegador le dará al archivo.
     */
    download: function (resultByte, fname) {
        var bytes = new Uint8Array(resultByte);
        var blob = new Blob([bytes]); // change resultByte to bytes

        var link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = fname;
        link.click();
    },

    /**
     * Sube los archivos a la url dada.
     * 
     * @param {Array<File>} files Archivos a subir
     * @param {string} upload_url url donde subir los archivos
     */
    upload: async function (files, upload_url) {
        let all_promises = [];
        for (const file of files) {
            all_promises.push(api_request("POST", upload_url, file));
        }

        try {
            await Promise.all(all_promises);
        } catch (e) {
            throw (e);
        }
    }
}