const moment = require("moment");


/**
 * Modulo que define como se ordenan los datos
 * @module sort
 */


/**
 * Devuelve la funcion que se utilizara para ordenar los items.
 * 
 * @function module:sort~fn
 * 
 * @param {Object} sorts 
 * @param {module:tablas~ColumnDef} _columnsObj 
 * @param {Function} sortFn 
 * @returns {Function}
 */
function fn(sorts, _columnsObj, sortFn) {
    return (a, b) => {
        let res = 0;
        for (const s of sorts) {
            if (!(s.column in _columnsObj)) {
                console.error(`Column ${s.column} does not exist`);
                continue;
            }
            if (!_columnsObj[s.column].calculateDisplayValue) {
                if (!(s.column in a) || a[s.column] == null) {
                    res = 1 * (s.order == "asc" ? 1 : -1);
                    break;
                }
                if (!(s.column in b) || b[s.column] == null) {
                    res = -1 * (s.order == "asc" ? 1 : -1);
                    break;
                }
            }

            let dataSource = _columnsObj[s.column]._dataSource;
            if (dataSource) {
                if (_columnsObj[s.column].multiple) {
                    res = (a[s.column].length - b[s.column].length);
                } else {
                    let itemA = dataSource.getItemByKeyColumnSync(a[s.column]);
                    let itemB = dataSource.getItemByKeyColumnSync(b[s.column]);
                    if (!itemA) {
                        res = 1 * (s.order == "asc" ? 1 : -1);
                        break;
                    }
                    if (!itemB) {
                        res = -1 * (s.order == "asc" ? 1 : -1);
                        break;
                    }
                    res = sortFn(itemA[dataSource.displayColumn], itemB[dataSource.displayColumn]);
                }
            } else {
                if (_columnsObj[s.column].calculateDisplayValue) {
                    res = sortFn(_columnsObj[s.column].calculateDisplayValue(a), _columnsObj[s.column].calculateDisplayValue(b));
                } else {
                    switch (_columnsObj[s.column].dataType) {
                        case "timestamp":
                            res = sortFn(moment(a[s.column]).utc().format(), moment(b[s.column]).utc().format());
                            break;
                        case "float":
                            res = a[s.column] - b[s.column];
                            break;
                        case "color":
                            var m1 = a[s.column].substr(1).match(a[s.column].length == 7 ? /(\S{2})/g : /(\S{1})/g);
                            let r1 = parseInt(m1[0], 16),
                                g1 = parseInt(m1[1], 16),
                                b1 = parseInt(m1[2], 16);

                            var m2 = b[s.column].substr(1).match(b[s.column].length == 7 ? /(\S{2})/g : /(\S{1})/g);
                            let r2 = parseInt(m2[0], 16),
                                g2 = parseInt(m2[1], 16),
                                b2 = parseInt(m2[2], 16);

                            res = (r2 - r1) * 299 + (g2 - g1) * 587 + (b2 - b1) * 114;
                            break;
                        default:
                            res = sortFn(a[s.column].toString(), b[s.column].toString());
                            break;
                    }

                }
            }
            if (res != 0) {
                res *= (s.order == "asc" ? 1 : -1);
                break;
            }
        }
        return res;
    }

}

module.exports = { fn };