const moment = require("moment");
const i18n = require("./utils/translate.js");
const naturalCompare = require('string-natural-compare');
const $ = require('jquery');
const bootstrap = require('bootstrap');
const { v4: uuidv4 } = require('uuid');

const spinner = require('./spinner.gif');

/**
 * Define la clase formBuilder para manipular una fila de la tabla
 * 
 * @module formBuilder
 */

/**
 * Permite manipular el formulario asociado a una fila de la tabla, tanto para editar como para mostrar.
 * 
 * Si el editorType no esta definido en la columna se utilizara la siguiente conversion.
 * Conversion dataType a editorType:
 * 
 *  * string => text
 *  * color => color
 *  * integer => integer
 *  * float => float
 *  * date => date
 *  * timestamp => datetime-local
 *  * boolean => checkbox
 *  * default => text
 * 
 * @constructor
 * @param {Object.<string, module:tablas~ColumnDef>} columnsObj Mapa de dataField -> Columna
 * @param {module:tablas~DataSourceDef} dataSource Origen de datos
 * @param {string} container Id del contenedor en el DOM.
 * @param {string} mode Indica si el formulario es un popup o form
 */
const formBuilder = function (columnsObj, dataSource, container, mode, actionsTemplate, getFilesUrl, eventEmitter) {
    const self = this;
    const _columnsObj = columnsObj;
    const _dataSource = dataSource;
    const _container = container;
    const _mode = mode;
    const _actionsTemplate = actionsTemplate;
    const _getFilesUrl = getFilesUrl;
    const _eventEmitter = eventEmitter;
    let _current_row = {};

    /**
     * Esta funcion se usa para los combos del formulario, y,
     * en caso de un onValueChanged de un combo relacionado, para que se filtren los datos a mostrar.
     * 
     * @param {string} column nombre de la columna (dataField) a la que se le tiene que refrescar los items (util solo para los selects)
     * @param {function} filter Filtro para los items a mostrar. Filtra los items ya descargados en el dataSource (no hace otro pedido)
     */
    this.fillOptionsFor = function (column, filter) {
        const item = _columnsObj[column];
        const select = $(`#${_container}_${item.dataField}`);
        this._fillOptionsFor(select, column, filter);
    }

    this._fillOptionsFor = function (select, column, filter) {
        // Busco el select en el form y lo vacio
        const item = _columnsObj[column];
        const items = item._dataSource.getAllItemsSync().filter(filter);

        items.sort((a, b) => {
            if (item._dataSource.calculateDisplayValue) {
                data_a = item._dataSource.calculateDisplayValue(a);
                data_b = item._dataSource.calculateDisplayValue(b);
                if (!data_a && !data_b) return 0;
                if (!data_a) return -1;
                if (!data_b) return 1;
                return naturalCompare(data_a, data_b, { caseInsensitive: true });
            }
            return naturalCompare(a[item._dataSource.displayColumn], b[item._dataSource.displayColumn], { caseInsensitive: true });
        });

        let options = `<option value="" ${(item.required || item.multiple) ? 'class="d-none" disabled' : ''}>${i18n.t("None Selected")}</option>`;

        let selected_id = null;

        for (const x of items) {
            let selected = false;
            if (Array.isArray(_current_row[item.dataField])) {
                for (const y of _current_row[item.dataField]) {
                    selected = y == x[item._dataSource.keyColumn];
                    if (selected) break;
                }
            } else {
                selected = x[item._dataSource.keyColumn] == _current_row[item.dataField];
            }

            if (selected) {
                selected_id = x[item._dataSource.keyColumn]
            }
            let text = x[item._dataSource.displayColumn]
            if (item._dataSource.calculateDisplayValue) {
                text = item._dataSource.calculateDisplayValue(x)
            }

            options += `<option value="${x[item._dataSource.keyColumn]}" ${selected ? "selected" : ""}>${text}</option>`;
        }
        if (!selected_id && items.length > 0) {
            const x = items[0]
            selected_id = x[item._dataSource.keyColumn]
        }

        select.empty().append(options);
        if (item.onValueChange) {
            item.onValueChange(selected_id, _columnsObj)
        }
    }

    /**
     * Dibuja el formulario
     * 
     * @param {module:dataSource~DataSourceDef} dataSource 
     * @param {$jquery} objeto $jquery del container donde se alojará el formulario
     * @param {Object} row objeto a editar por el formulario 
     * @param {boolean} editable si es posible editar el objeto asociado al formulario
     * @param {string} editMode puede ser {'add'|'edit'|'show'}
     */
    this.draw = function (row, editable, editMode) {
        $(`#${_container}`).empty();
        _current_row = row;

        const form = $("<form class='tablas-form'>");
        form.append(`<input type="hidden" class="form-control" name="${_dataSource.keyColumn}" id="${_container}_${_dataSource.keyColumn}" value="${row[_dataSource.keyColumn] || ''}">`)
        for (const item of Object.values(_columnsObj)) {
            let required = (item.required) ? 'required-field' : '';
            let value = (item.dataField in row) ? row[item.dataField] : (item.defaultValue) ? item.defaultValue : "";
            if (item.onlyAdd && editMode != 'add') {
                continue
            }
            let editorEnabled = editable;
            if (item.editorDisabled) {
                editorEnabled = false;
            }
            if (item.editorTemplate) {
                item.editorTemplate(form, row, item, editorEnabled);
            } else if (item.dataSource) {
                let select = $(`<select class="form-control" data-dataField="${item.dataField}" id="${_container}_${item.dataField}" ${item.multiple ? "multiple" : ""} name="${item.dataField}" ${editorEnabled ? "" : "disabled"} ${item.required ? "required" : ""}></select>`);
                let selectContainer = $(`<div class="mb-3 ${item.editorType == "hidden" ? "d-none" : ""}">
                    <label class="label-field ${required}" for="${_container}_${item.dataField}">${item.caption}${(item.required) ? '<div class="d-inline text-danger">*</div>' : ''}</label>
                </div>`);
                selectContainer.append(select);
                form.append(selectContainer);
                if (!editorEnabled) {
                    form.append($(`<input type="hidden" name="${item.dataField}" value="${value}">`))
                }
                this._fillOptionsFor(select, item.dataField, () => true);
            } else {
                let editorType = item.editorType;
                if (!editorType) {
                    switch (item.dataType) {
                        case "string":
                            editorType = "text";
                            break;
                        case "file":
                            editorType = "file";
                            break;
                        case "color":
                            editorType = "color";
                            break;
                        case "integer":
                            editorType = "integer";
                            break;
                        case "float":
                            editorType = "float";
                            break;
                        case "date":
                            editorType = "date";
                            break;
                        case "timestamp":
                            editorType = "timestamp";
                            break;
                        case "boolean":
                            editorType = "checkbox";
                            break;
                        default:
                            editorType = "text";
                    }
                }

                /**
                 * 
                 * @typedef {Object} module:formBuilder~EditorTypeDef
                 * 
                 * Puede tomar los siguientes valores:
                 * 
                 *  * none (sin editor)
                 *  * checkbox
                 *  * color
                 *  * date
                 *  * datetime-local
                 *  * email
                 *  * file
                 *  * hidden
                 *  * image
                 *  * month
                 *  * number
                 *  * password
                 *  * radio
                 *  * range
                 *  * reset
                 *  * search
                 *  * submit
                 *  * tel
                 *  * text
                 *  * time
                 *  * url
                 *  * week
                 *  * textarea
                 *  * checkbox
                 *  * integer
                 *  * float
                 */
                switch (editorType) {
                    case "checkbox":
                        form.append($(`<div class=" form-check-group mb-3"> 
                         <label class="label-field ${required}" for="${_container}_${item.dataField}">${item.caption}${(item.required) ? '<div class="d-inline text-danger">*</div>' : ''}</label>
                            <input type="hidden" value="false" name="${item.dataField}">
                            <input data-dataField="${item.dataField}" class="checker form-check-input" type="checkbox" value="true" name="${item.dataField}" id="${_container}_${item.dataField}" ${editorEnabled ? "" : "disabled"} ${value ? "checked" : ""} ${item.required ? "required" : ""}>
                        </div>`));
                        if (!editorEnabled) {
                            form.append($(`<input type="hidden" name="${item.dataField}" value="${value}">`))
                        }
                        break;
                    case "timestamp":
                        // Manipulacion de value para pasar a hora local
                        value = moment(value).local().format("YYYY-MM-DDTHH:mm:ss");
                        form.append($(`<div class="mb-3">
                            <label class="label-field ${required}" for="${_container}_${item.dataField}">${item.caption}${(item.required) ? '<div class="d-inline text-danger">*</div>' : ''}</label>
                            <input data-dataField="${item.dataField}" type="datetime-local" class="form-control" name="${item.dataField}" id="${_container}_${item.dataField}" aria-describedby="${item.dataField}" placeholder="${item.caption}" value="${value}" ${editorEnabled ? "" : "readonly"} ${item.required ? "required" : ""}>
                        </div>`));
                        break;
                    case "textarea":
                        form.append($(`<div class="mb-3">
                            <label class="label-field ${required}" for="${_container}_${item.dataField}">${item.caption}${(item.required) ? '<div class="d-inline text-danger">*</div>' : ''}</label>
                            <textarea data-dataField="${item.dataField}" class="form-control" id="${_container}_${item.dataField}" name="${item.dataField}" aria-describedby="${item.dataField}" placeholder="${item.caption}" ${editorEnabled ? "" : "readonly"} ${item.required ? "required" : ""}>${value == null ? '' : value}</textarea>
                        </div>`));
                        break;
                    case "integer":
                    case "float":
                        form.append($(`<div class="mb-3">
                            <label class="label-field ${required}" for="${_container}_${item.dataField}">${item.caption}${(item.required) ? '<div class="d-inline text-danger">*</div>' : ''}</label>
                            <input data-dataField="${item.dataField}" type="number" step="${editorType == "integer" ? "1" : "0.01"}" class="form-control" name="${item.dataField}" id="${_container}_${item.dataField}" aria-describedby="${item.dataField}" placeholder="${item.caption}" value="${value}" ${editorEnabled ? "" : "readonly"} ${item.required ? "required" : ""}>
                        </div>`));
                        break;
                    case "file":
                        let container = $(`<div class="mb-3">
                                <label class="label-field ${required}" for="${_container}_${item.dataField}">${item.caption}${(item.required) ? '<div class="d-inline text-danger">*</div>' : ''}</label>
                            </div>`).appendTo(form);

                        let content = $(`<div class="mb-3 file-cards-container" id="file-cards-container-${_container}_${item.dataField}">`).appendTo(container);

                        const drawFileCard = (fileObj) => {
                            let _name = "";
                            let _linkname = "";
                            let _type = "";
                            let _fromDS;
                            let _input;
                            let _isImage = false;

                            if (fileObj instanceof DataTransfer) {
                                _name = fileObj.files[0].name;
                                _type = fileObj.files[0].type;
                                _fromDS = false;
                                _input = $(`<input type='file' name="${item.dataField}" data-dataField="${item.dataField}" class='d-none'>`);
                                _input[0].files = fileObj.files;
                            } else {
                                _name = fileObj.originalname;
                                _linkname = fileObj.filename;
                                _type = fileObj.type;
                                _fromDS = true;
                                _isImage = true;
                                _input = $(`<input type="hidden" name="${item.dataField}" data-dataField="${item.dataField}" value="${encodeURI(JSON.stringify(fileObj))}">`);
                            }

                            let thumb = '<i class="far fa-file fa-4x"></i>';
                            if (_type.match(/image/)) {
                                if (_fromDS) {
                                    thumb = `<img class="thumb" src="${_getFilesUrl}${_linkname}">`;
                                } else {
                                    thumb = $(`<img class="thumb" src="${spinner}">`);

                                    // Cargo el thumbnail desde el archivo cargado
                                    (function (thumbdiv) {
                                        const reader = new FileReader();
                                        reader.addEventListener("load", function () {
                                            thumb.prop("src", this.result);
                                        });
                                        reader.readAsDataURL(fileObj.files[0])
                                    })(thumb)

                                }
                                _isImage = true;
                            }

                            let ret = $(`<div class="file-card ${_isImage ? "cursor-pointer" : ""}" data-isimage="${_isImage}" data-type="${_type}" data-filename="${_linkname}">`);

                            ret.append($("<div class='thumb-container'>").append(thumb));
                            ret.append($(`<span class="file-card-text text-truncate text-center" title="${_name}">${_name}</span>`));

                            if (editorEnabled) {
                                ret.append(`<span class="file-card-remove"><i class="fas fa-times remove-card"></i></span>`);
                            }

                            ret.append(_input);

                            return ret;
                        }

                        $(`<div>
                            <span class="btn btn-r2 fileinput-button  ${editorEnabled ? "" : "disabled"} m-r-3" >
                                <i class="fa fa-fw fa-plus"></i>
                                <span>${i18n.t('Add file...')}</span>
                                <input data-dataField="${item.dataField}" type="file" ${item.multiple ? "multiple" : ""} id="${_container}_${item.dataField}" ${editorEnabled ? "" : "disabled"} ${item.required ? "required" : ""} accept="${item.mimeType}">
                            </span>
                        </div>`).appendTo(container);

                        if (Array.isArray(value)) {
                            for (const x of value) {
                                content.append(drawFileCard(x));
                            }
                        } else if (value) {
                            content.append(drawFileCard(value));
                        }

                        $('body').on('click', '.file-card-remove', function (e) {
                            e.preventDefault();
                            e.stopPropagation();
                            $(this).parent().remove();
                        });

                        $('body').off('click', '.file-card').on('click', '.file-card', function (e) {
                            e.preventDefault();
                            e.stopPropagation();

                            if ($(this).data('filename') != '') {
                                window.open(_getFilesUrl + $(this).data('filename'), '_blank');
                            } else {

                                if (!$(this).data("isimage")) {
                                    return;
                                }

                                const reader = new FileReader();
                                reader.addEventListener("load", function () {
                                    let w = window.open("");
                                    w.document.write("<img src='" + this.result + "'>");
                                });
                                reader.readAsDataURL($("input", $(this))[0].files[0]);
                            }
                        });

                        $('body').off('change', `#${_container}_${item.dataField}`).on('change', `#${_container}_${item.dataField}`, function () {
                            let content = $(`#file-cards-container-${_container}_${item.dataField}`);

                            if (!item.multiple) {
                                content.html('');
                            }

                            for (const file of this.files) {
                                let dt = new DataTransfer();
                                dt.items.add(file);
                                content.append(drawFileCard(dt));
                            }
                            this.value = "";
                        });

                        break;
                    case 'color':
                        form.append($(`<div class="mb-3">
                            <label class="label-field ${required}" for="${_container}_${item.dataField}">${item.caption}${(item.required) ? '<div class="d-inline text-danger">*</div>' : ''}</label>
                            <input data-dataField="${item.dataField}" type="${editorType}" class="form-control" name="${item.dataField}" id="${_container}_${item.dataField}" aria-describedby="${item.dataField}" placeholder="${item.caption}" value="${value}" ${editorEnabled ? "" : "disabled"} ${item.required ? "required" : ""}>
                        </div>`));
                        if (!editorEnabled) {
                            form.append($(`<input type="hidden" name="${item.dataField}" value="${value}">`))
                        }
                        break;
                    case 'hidden':
                        form.append($(`
                            <input data-dataField="${item.dataField}" type="${editorType}" class="form-control" name="${item.dataField}" id="${_container}_${item.dataField}" aria-describedby="${item.dataField}" placeholder="${item.caption}" value="${value}" ${editorEnabled ? "" : "readonly"} ${item.required ? "required" : ""}>
                        `));
                        break;
                    case 'none':
                        break;
                    default:
                        form.append($(`<div class="mb-3">
                            <label class="label-field ${required}" for="${_container}_${item.dataField}">${item.caption}${(item.required) ? '<div class="d-inline text-danger">*</div>' : ''}</label>
                            <input data-dataField="${item.dataField}" type="${editorType}" class="form-control" name="${item.dataField}" id="${_container}_${item.dataField}" aria-describedby="${item.dataField}" placeholder="${item.caption}" value="${value ?? ''}" ${editorEnabled ? "" : "readonly"} ${item.required ? "required" : ""}>
                        </div>`));
                }
            }
        }

        let submit = ""

        if (editable) {
            submit = `<button type="submit" class="btn btn-r2 btn_submit mt-2">${i18n.t("Submit")}</button>`;
        }

        const formBtns = $(`<div class="mb-3 form-btns" role="group">
            ${submit}
            <button type="button" class="btn btn-danger btn_cancel mt-2">${i18n.t("Cancel")}</button>
        </div>`);

        form.append(formBtns);

        form.on("input", (a) => {
            if (a.originalEvent) a = a.originalEvent;
            const dataField = $(a.target).attr("data-dataField");
            if (!dataField) {
                console.warn('deberia tener data-dataField');
                return;
            }

            if (columnsObj[dataField].onValueChange && typeof columnsObj[dataField].onValueChange === "function") {
                columnsObj[dataField].onValueChange.call(self, a.target.value, _columnsObj)
            }
        });

        if (typeof _actionsTemplate !== "undefined") {
            _actionsTemplate(formBtns, row, editMode);
        }

        const modalTemplate = `
            <div class="edit_modal modal fade">
                <div class="modal-dialog">
                    <div class="modal-content">
                        <div class="modal-header modal-header-r2">
                            <h4 class="modal-title">${i18n.t(editMode)}</h4>
                            <button type="button" class="btn-close close" data-dismiss="modal" aria-hidden="true"></button>
                        </div>
                        <div class="modal-body">
                            ${form.prop('outerHTML')}
                        </div>
                    </div>
                </div>
            </div>
        `;

        if (_mode == 'popup') {
            $('.edit_modal', `#${_container}`).modal({
                backdrop: true,
                show: false
            });
            $(modalTemplate).appendTo($(`#${_container}`));
            $('.edit_modal').modal("show");
        } else {
            $(`#${_container}`).addClass('form-style');
            $(form).appendTo($(`#${_container}`));
        }

        $("input:not([type='hidden']),select,textarea", form).trigger("input");

        $('.btn_cancel').on('click', () => {
            self.remove();
            _eventEmitter.emit('hideForm');
        });
    }

    this.remove = function () {
        if (_mode == 'popup') {
            $('.edit_modal').modal("hide");
            $('.edit_modal').on('hidden.bs.modal', function (event) {
                $(`#${_container}`).empty();
            });
        } else {
            $(`#${_container}`).empty();
        }
    }

    this.validate = (validator, obj, files) => {
        const dict = validator(obj, files);
        if (dict === true) {
            return true;
        }
        for (const dataField in dict) {
            if (Object.hasOwnProperty.call(dict, dataField)) {
                const msg = dict[dataField];
                $(`#${_container}_${dataField}`)[0].setCustomValidity(msg);
                $(`#${_container}_${dataField}`).after(`<div class="invalid-feedback">${msg}</div>`)
            }
        }
        $('form', `#${_container}`).addClass('was-validated');
        return false;
    }

    /**
     * A partir del container donde se encuentra el formulario serializa un objeto.
     * 
     * @param {string} selector selector para el container donde se encuentra el form a seralizar
     * @returns Fila del formulario
     */
    this.serialize = function () {
        let fd = new FormData($("form", `#${_container}`)[0]);

        let obj = {};
        let files = [];

        for (var pair of fd.entries()) {
            let key = pair[0];
            let value = pair[1];
            let found = key in _columnsObj ? _columnsObj[key] : null;
            if (found) {
                let setKeyInObj = function (obj, item, key, val) {
                    if (item.multiple) {
                        if (!(key in obj)) obj[key] = [];
                        obj[key].push(val);
                    } else {
                        obj[key] = val;
                    }

                }

                switch (found.dataType) {
                    case "integer":
                        setKeyInObj(obj, found, key, parseInt(value));
                        break;
                    case "float":
                        setKeyInObj(obj, found, key, parseFloat(value));
                        break;
                    case "timestamp":
                        let mom = moment(value, "YYYY-MM-DDTHH:mm:ss");
                        if (mom.isValid()) {
                            setKeyInObj(obj, found, key, mom.utc().format());
                        } else {
                            setKeyInObj(obj, found, key, null);
                        }
                        break;
                    case "date":
                        if (value == "") value = null;
                    case "string":
                    case "color":
                        setKeyInObj(obj, found, key, value);
                        break;
                    case "boolean":
                        setKeyInObj(obj, found, key, value === "true");
                        break;
                    case "file":
                        if (typeof value == 'string') {
                            value = JSON.parse(decodeURI(value));
                            setKeyInObj(obj, found, key, value);
                            break;
                        }
                        if (value.name == "") break;
                        const ext = value.name.split(".").pop();
                        const fname = uuidv4();
                        setKeyInObj(obj, found, key, {
                            filename: fname + "." + ext,
                            originalname: value.name,
                            size: value.size,
                            type: value.type,
                        });

                        const fd = new FormData();
                        fd.append("userfile", value, fname + "." + ext);
                        files.push(fd);
                        break;
                    default:
                        setKeyInObj(obj, found, key, value.toString());
                }
            }
            if (key == _dataSource.keyColumn) {
                if (value == parseInt(value)) {
                    obj[key] = parseInt(value);
                } else {
                    obj[key] = value;
                }
            }

        }
        return { obj, files };
    }
}

module.exports = formBuilder;