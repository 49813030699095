const dataSource = require("./dataSource");
const naturalCompare = require('string-natural-compare');
const debounce = require("debounce");
const filterItems = require("./filter");
const $ = require('jquery');
const events = require('events');
const i18n = require('./utils/translate');
const formBuilder = require('./formbuilder');
const ID = require('./utils/ID');
const cellTemplater = require('./cellTemplater');
const sort = require("./sort");

// css
require('./style.css');

'use strict';

/**
 * Construye un objecto Tablas a partir de un objecto de configuración.
 * 
 * @constructor
 * @param {module:tablas~TableConfigDef} configObj objecto de configuración
 */
let Tablas = function (configObj) {
    var self = this;
    let _mainDS = null;
    let _items = [];
    let _displayItems = [];
    let _pageItems = [];
    let _columnsObj = {};
    self.getColumnsObj = () => _columnsObj;
    let _formBuilder;
    self._getFormBuilder = () => _formBuilder;

    let _state = {
        sorts: [],
        currentPage: 0,
        filters: {},
    }
    self.getState = () => _state;

    let _first_in_page = 0;
    let _last_in_page = 0;
    let _count_filtered = 0;
    let _count_all = 0;
    let _eventEmitter = new events.EventEmitter();

    /**
     * Objeto de configuracion de la tabla
     * 
     * @typedef {Object} module:tablas~TableConfigDef
     * @property {string} container - ID del contenedor en donde insertar la tabla.
     * @property {module:tablas~EditingDef} editing - objeto que define el comportamiento de la edicion de entidades
     * @property {ConfirmFnDef} confirmFn - funcion a ejecutarse en caso de borrar una entidad (default confirm()). Debe devolver una promesa que complete en caso de éxito ya sea con el parametro True o False.
     * @property {boolean} filterRow - Si la fila de filtros esta habilitada (default True)
     * @property {boolean} serverSide - La paginacion, el filtrado y orden corren por cuenta del servidor (default false)
     * @property {boolean} persistentState - Indica si la libreria deberia guardar el estado en localStorage para que se recupere cuando se recree la tabla.
     * @property {module:dataSource~DataSourceDef} dataSource - Fuente de datos de la tabla.
     * @property {Array<module:tablas~ColumnDef>} columns - Definicion de columnas
     * @property {module:tablas~ValidationCb} validator - Callback que nos indica si la fila (objeto) es valida.
     * @property {module:tablas~PaginationDef} pagination - Manejo del paginado de la tabla.
     * @property {string} uploadUrl - url donde se subiran los archivos para las columnas de tipo file.
     * @property {string} getFilesUrl - url de donde se obtienen los archivos de las columnas tipo file.
     */
    var _options = $.extend(true, {
        container: "",
        dataSource: {},
        uploadUrl: "",
        getFilesUrl: "",
        columns: [],
        persistentState: false,
        /**
         * @typedef {Object} module:tablas~PaginationDef
         * 
         * Opciones de paginación
         * 
         * @property {boolean} enable - Habilita/deshabilita la función de paginacion
         * @property {string} position - Ubicación vertical de la barra de paginación ('top' | 'bottom') (default: 'bottom')
         * @property {string} alignment - Ubicación horizontal de la barra de paginación ('left' | 'center' | 'right') (default: 'left')
         * @property {int} length - Tamaño de la página (default: 10)
         * @property {int} initial - Número de pagina inicial (default: 0)
         */
        pagination: {
            enable: true,
            position: "bottom",
            alignment: "left",
            length: 10,
            initial: 0,
        },
        /**
         * 
         * @typedef {Object} module:tablas~EditingDef
         * 
         * Opciones de edicion
         *
         * @property {string} [container={TableConfigDef.container}_form] ID del contenedor en donde montar el formulario de edicion (por defecto es <parent_container>_edit)
         * @property {boolean | function} canEdit - Si puede editar una entidad (default: True)
         * @property {boolean | function} canShow - Si puede solo ver una entidad (default: True)
         * @property {boolean | function} canAdd - Si puede crear una entidad (default: True)
         * @property {boolean | function} canDelete - Si puede eliminar una entidad (default: True)
         * @property {boolean} templateMode - Si es true, no borra el contenido del contenedor del form ni lo llena con lo que diga el formbuilder (para proveer un mecanismo para mostrar formularios personalizados).
         * @property {function} actionAdd - Funcion que reemplaza la accion por defecto del boton Add
         * @property {function} actionEdit - Funcion que reemplaza la accion por defecto del boton Edit
         * @property {function} actionShow - Funcion que reemplaza la accion por defecto del boton Show
         * @property {function} actionDelete - Funcion que reemplaza la accion por defecto del boton Delete
         * @property {function} extraButtonsTemplate - Callback para agregar acciones ademas de mostrar, editar y borrar.
         * @property {function} actionsTemplate - Callback para agregar acciones ademas de Guardar y Cancelar al formulario de edicion
         */
        editing: {
            container: configObj.container + "_edit",
            mode: 'form',
            canAdd: true,
            canDelete: true,
            canEdit: true,
            canShow: true,
            templateMode: false,
            actionsTemplate: function () { },
            actionAdd: null,
            actionEdit: null,
            actionDelete: null,
            actionShow: null,
            extraButtonsTemplate: null,
        },
        /**
         * Funcion que devuelve una promesa que se resuelva con True o False
         * 
         * @callback module:tablas~ConfirmFnDef
         * @example
         * function (prompt) {
         *     return new Promise((resolve, reject) => {
         *         resolve(confirm(prompt));
         *     });
         * }
         * 
         * @returns {Promise<boolean>} True: aceptado, False: cancelado
         */
        confirmFn: function (prompt) {
            return new Promise((resolve, reject) => {
                resolve(confirm(prompt));
            });
        },
        errorFn: function (message) {
            alert(message);
        },
        validator: () => true,
        plugins: [],
        serverSide: false,
        sort: [],
        filterRow: true,
        class: {
            addButton: `<i class="fas fa-plus-circle"></i> ` + i18n.t('new'),
            editButton: `<i class="fas fa-edit"></i>`,
            deleteButton: `<i class="fas fa-trash-alt"></i>`,
            showButton: `<i class="fas fa-eye"></i>`,
        },
    }, configObj);

    /**
     * @typedef {Object} module:tablas~ColumnDef
     *
     * @property {string} caption - Nombre de la columna para mostrar. Por defecto se usa `dataField`.
     * @property {string} dataField - Key de la fuente de datos de donde sacar los datos, si se usa `calculateDisplayValue`, este valor se omite.
     * @property {boolean} required - Indica si este campo es requerido en el formulario
     * @property {boolean} showInTable - Indica si este campo se muestra en la tabla
     * @property {string} editorType - Tipo de editor a utilizar, pude ser cualquier tipo de input de HTML mas integer, float, date, timestamp, boolean
     * @property {EditorTemplateDef} editorTemplate - Callback para establecer un editor custom
     * @property {DataSourceDef} dataSource - Fuente de datos de la columna (foreign keys)
     * @property {string} mimeType - Indica el mime del archivo asociado (solo tiene sentido si la columna es del tipo archivo)
     * @property {boolean} onlyAdd - Deshabilita el editor cuando se está editando.
     * @property {EventCb} onClick - Evento que se ejecuta al hacer click
     * @property {EventCb} onDblClick - Evento que se ejecuta al hacer doble click
     * @property {OnValueChangedCb} onValueChanged - Callback para avisar de un cambio de valor en el formulario
     * @property {TemplateDef} template - Template para dibujar la celda de la tabla
     * @property {string} class - Clase a agregar a la celda
     * @property {boolean} export - Indica si la columna debe exportarse
     * @property {boolean} multiple - Indica si los datos vienen como array, y como se deberia mostrar en el formulario (solo importa con selects y files)
     * @property {any} filterDefault - Valor por defecto en los filtros
     * @property {module:tablas~CalculateDisplayValueCb} calculateDisplayValue - Funcion que calcula el valor a mostrar en la celda (y en los filtros, si aplica). Esta funcion tiene precedencia sobre dataField
     * @property {any} defaultValue - Valor por defecto en el form
     * @property {boolean} editorDisabled - Deshabilita la columna en el formulario (usa read-only).
     * @property {boolean} enableFilter - Deshabilita el filtro para la columna.
     * @property {string} dataType - Tipo de datos de la columna, se utiliza para especificar como formatear los datos en la tabla, puede ser: boolean, string, file, color, integer, float, date, timestamp, cualquier otro se formatea como string (no se formatea)
     */
    for (let i = 0; i < _options.columns.length; i++) {
        _options.columns[i] = $.extend(true, {
            caption: _options.columns[i].dataField,
            dataField: '',
            dataType: '-',
            editorType: null,
            onlyAdd: false,
            editorTemplate: null,
            editorDisabled: false,
            required: false,
            dataSource: null,
            onClick: null,
            onDblClick: null,
            template: null,
            class: "",
            calculateDisplayValue: null,
            export: true,
            showInTable: true,
            mimeType: "*/*",
            multiple: false,
            onValueChanged: null,
            defaultValue: null,
            enableFilter: true,
        }, _options.columns[i]);

        _columnsObj[_options.columns[i].dataField] = _options.columns[i];
    }

    let _container = $("#" + _options.container);
    if (_container.length == 0) {
        throw new Error('No existe el contenedor en el dom');
    }
    let init = {
        init: function () {
            _state.currentPage = _options.pagination.initial;
            _state.sorts = _options.sort;
            _mainDS = new dataSource(_options.dataSource);
            _formBuilder = new formBuilder(_columnsObj, _mainDS, _options.editing.container, _options.editing.mode, _options.editing.actionsTemplate, _options.getFilesUrl, _eventEmitter);

            // inicializamos los plugines (si hay)
            for (const pl of _options.plugins) {
                pl.init(self, _options);
            }

            self.recreate();
            return self;
        }
    };

    this.getDataSource = () => _mainDS;

    /**
     * Vacia completamente la tabla y vuelve a dibujar todo su contenido.
     */
    this.recreate = function () {
        try {
            if (_options.persistentState && `${_options.container}_state` in window.localStorage) {
                _state = JSON.parse(window.localStorage.getItem(`${_options.container}_state`))
            }
        } catch (e) {
            // No hay soporte para localStorage :shrug:
        }

        _container.html("");
        let _inner_table = $("<div class='inner-table'>");
        if (_options.pagination.enable && _options.pagination.position == "top") {
            let pagination = $(`<div class="pagination-buttons m-b-15">`);
            switch (_options.pagination.alignment) {
                case 'left':
                    pagination.addClass('left-pag');
                    break;
                case 'center':
                    pagination.addClass('center-pag');
                    break;
                case 'right':
                    pagination.addClass('right-pag');
                    break;

            }
            pagination.appendTo(_inner_table);
        }

        const card = $(`<div class="card">`).appendTo(_inner_table)
        const cardBody = $(`<div class="card-body">`).appendTo(card)
        let table = $("<table class='table table-sm table-striped'>").appendTo(cardBody);
        _inner_table.appendTo(_container);
        if (_options.pagination.enable && _options.pagination.position == "bottom") {
            let pagination = $(`<div class="pagination-buttons m-t-15">`);
            switch (_options.pagination.alignment) {
                case 'left':
                    pagination.addClass('left-pag');
                    break;
                case 'center':
                    pagination.addClass('center-pag');
                    break;
                case 'right':
                    pagination.addClass('right-pag');
                    break;

            }
            pagination.appendTo(_inner_table);
        }

        let thead = $("<thead>").appendTo(table);

        // Captions de columnas
        let tr_head = $("<tr>").appendTo(thead);
        _options.columns.forEach(column => {
            let orderClass = "header-columns";
            //Busco esta columna en el array de orden
            for (const s of _state.sorts) {
                if (s.column == column.dataField) {
                    orderClass += " sort_ " + s.order;
                }
            }

            let th = $("<th>")
                .attr("hidden", !column.showInTable)
                .html(column.caption)
                .attr("data-datafield", column.dataField)
                .addClass(orderClass)
                .addClass(column.class)
                .appendTo(tr_head);
        });

        if (_options.editing.canEdit || _options.editing.canShow || _options.editing.canAdd || _options.editing.canDelete || _options.editing.extraButtonsTemplate) {
            tr_head.append($("<th>").addClass("header-columns"))
        }

        // Eventos de sort
        tr_head.on("click", "th", function (e) {
            let column = $(e.target).data("datafield");
            if (!column) return;

            if (e.shiftKey) {
                let found = false;
                for (const s of _state.sorts) {
                    if (s.column == column) {
                        s.order = (s.order == "asc") ? "desc" : "asc";
                        found = true;
                        break;
                    }
                }
                if (!found) {
                    _state.sorts.push({
                        column: column,
                        order: "asc"
                    });
                }
            } else {
                if (_state.sorts.length && _state.sorts[0].column == column) {
                    _state.sorts = [{
                        column: column,
                        order: (_state.sorts[0].order == "asc") ? "desc" : "asc"
                    }]
                } else {
                    _state.sorts = [{
                        column: column,
                        order: "asc"
                    }];
                }
            }

            self.update();
        })

        let tr_filter;
        if (_options.filterRow || _options.editing.canAdd) {
            tr_filter = $("<tr>")
                .addClass("filters")
                .appendTo(thead);
        }

        // inicializamos los datasources
        _options.columns.forEach(column => {
            if (column.dataSource) {
                column._dataSource = new dataSource(column.dataSource);
                column._dataSource.getItems();
            }
        });

        // Filtros
        if (_options.filterRow) {
            _options.columns.forEach(column => {
                let th = $("<th class='column-filter'>").addClass(column.class).attr("hidden", !column.showInTable)
                    .appendTo(tr_filter);

                if (!column.enableFilter) return;

                let value = '';
                if (typeof column.filterDefault !== 'undefined' && !(column.dataField in _state.filters)) {
                    if (!column.dataSource) {
                        column.filterDefault = column.filterDefault.toString();
                    } else {
                        if (Array.isArray(column.filterDefault)) {
                            column.filterDefault = column.filterDefault.map(String);
                        } else {
                            column.filterDefault = [column.filterDefault.toString()];
                        }
                    }

                    _state.filters[column.dataField] = column.filterDefault;
                }

                if (column.dataField in _state.filters) {
                    value = _state.filters[column.dataField];
                }

                if (!column.dataSource && column.dataType != 'boolean') {
                    $("<input class='form-control'>")
                        .attr("hidden", !column.showInTable)
                        .prop("type", "search")
                        .prop("placeholder", column.caption)
                        .val(value)
                        .attr("data-datafield", column.dataField)
                        .appendTo(th);
                } else {
                    let select = $('<select class="form-control">')
                        .attr("hidden", !column.showInTable)
                        .attr("data-datafield", column.dataField)
                        .prop("placeholder", column.caption)
                        .appendTo(th);
                    $('<option>')
                        .val('')
                        .html(i18n.t('all'))
                        .appendTo(select);
                    if (column.dataSource) {
                        column._dataSource = new dataSource(column.dataSource);
                        column._dataSource.getItems().then((items) => {
                            // ordenamos los items del filtro
                            items.sort((a, b) => {
                                if (column._dataSource.calculateDisplayValue) {
                                    data_a = column._dataSource.calculateDisplayValue(a);
                                    data_b = column._dataSource.calculateDisplayValue(b);
                                    if (!data_a && !data_b) return 0;
                                    if (!data_a) return -1;
                                    if (!data_b) return 1;
                                    return naturalCompare(data_a, data_b, { caseInsensitive: true });
                                }
                                return naturalCompare(a[column._dataSource.displayColumn], b[column._dataSource.displayColumn], { caseInsensitive: true });
                            });
                            for (const item of items) {
                                let display = item[column._dataSource.displayColumn];
                                if (column._dataSource.calculateDisplayValue) {
                                    display = column._dataSource.calculateDisplayValue(item);
                                }

                                let selected = false;
                                if (column.dataField in _state.filters && _state.filters[column.dataField] == item[column._dataSource.keyColumn].toString()) {
                                    selected = true;
                                }

                                $(`<option>`)
                                    .prop("selected", selected)
                                    .val(item[column._dataSource.keyColumn])
                                    .html(display)
                                    .appendTo(select);
                            }
                        }).catch((error) => {
                            _options.errorFn(error)
                        });
                    } else {
                        select.addClass('boolean-filters');
                        $(`<option ${(column.filterDefault === 'true') ? "selected" : ""}>`)
                            .val('true')
                            .html(i18n.t('True'))
                            .appendTo(select);
                        $(`<option ${(column.filterDefault === 'false') ? "selected" : ""}>`)
                            .val('false')
                            .html(i18n.t('False'))
                            .appendTo(select);
                    }

                }
            });

            let upd = debounce(self.update, 500, false);
            const inputHandler = function (e) {
                let column = $(e.target).data("datafield");
                if (e.target.value === "") {
                    delete _state.filters[column];
                } else {
                    _state.filters[column] = $(e.target).val();
                }
                _state.currentPage = 0;
                upd();
            };
            tr_filter.on("input", "th", inputHandler);
            tr_filter.on("change", "th", inputHandler);
            self.inputHandler = inputHandler

            if (_options.editing.canEdit || _options.editing.canShow || _options.editing.canAdd || _options.editing.canDelete || _options.editing.extraButtonsTemplate) {
                let th = $(`<th title='${i18n.t('new')}' class='commands'>`);
                if (_options.editing.canAdd) {
                    th.html($(`<button class='add_btn btn'>${_options.class.addButton}</button>`));
                }
                tr_filter.append(th);
            }
        } else if (_options.editing.canAdd) {
            // agregar un th con un colspan para toda las columnas.
            // agrego el th para el boton 
            tr_filter.append($(`<th colspan="${_options.columns.length}">`));
            let th = $(`<th title='${i18n.t('new')}' class='commands'>`);
            th.html($(`<button title='${i18n.t("new")}' class='add_btn btn'>${_options.class.addButton}</button>`));
            tr_filter.append(th);
        }

        thead.off("click", ".add_btn").on("click", ".add_btn", (_options.editing.actionAdd) ? _options.editing.actionAdd : function (e) {
            if (!_options.editing.templateMode) {
                _formBuilder.draw({}, true, 'add');
            }
            /**
             * showForm event, se emite antes de mostrar un formulario
             *
             * @event Tablas#showForm
             * 
             * @type {object}
             * @property {boolean} editable - indica si se solicitó mostrar un formulario editable
             * @property {object} row - objeto fila de la tabla para completar el formulario
             */
            _eventEmitter.emit('showForm', {
                editable: true,
                row: {}
            });
        });


        let tbody = $("<tbody>").appendTo(table);

        // Avisamos a los plugines que se recreo la tabla
        for (const pl of _options.plugins) {
            pl.onCreate(_container);
        }

        self.update();
    };

    this.getFilterFunction = filterItems.filter

    /**
     * Redibuja el contenido de la tabla, solo el cuerpo (tbody)
     * No fuerza la solicitud de los datos al servidor.
     *
     */
    this.update = async function () {
        /**
         * preUpdate event, se emite antes de actualizar el body de la tabla
         *
         * @event Tablas#preUpdate
         * 
         */
        _eventEmitter.emit('preUpdate');

        try {
            if (_options.persistentState) {
                window.localStorage.setItem(`${_options.container}_state`, JSON.stringify(_state))
            }
        } catch (e) {
            // No hay soporte para localStorage :shrug:
        }

        let tbody = $("tbody", $("#" + _options.container));

        let columnsQuantity = _options.columns.filter(x => x.showInTable).length;
        if (_options.editing.canEdit || _options.editing.canShow || _options.editing.canAdd || _options.editing.canDelete || _options.editing.extraButtonsTemplate) {

            columnsQuantity += 1;
        }
        tbody.html(`<tr><td class="text-center" colspan="${columnsQuantity}">Cargando datos....</td><tr>`);

        // Resuelvo todos los datasources de las columnas
        for (const key in _columnsObj) {
            if (Object.hasOwnProperty.call(_columnsObj, key)) {
                const element = _columnsObj[key];
                if (element._dataSource) {
                    await element._dataSource.getItems();
                }
            }
        }

        // Cosas de paginacion que se pueden hacer siempre
        _first_in_page = 0, _last_in_page = 0;
        _first_in_page = (_state.currentPage) * _options.pagination.length;
        _last_in_page = (_state.currentPage) * _options.pagination.length + _options.pagination.length;

        if (_options.serverSide) {
            // Preparo los parametros a partir del estado

            const params = new URLSearchParams();
            params.append("_serverSide", 1);

            if (_options.pagination.enable) {
                params.append("_start", _first_in_page);
                params.append("_end", _last_in_page);
            }

            let _sort = [];
            let _order = [];
            for (const s of _state.sorts) {
                _sort.push(s.column);
                _order.push(s.order);
            }
            params.append("_sort", _sort.join(","))
            params.append("_order", _order.join(","))

            for (const key in _state.filters) {
                if (Object.hasOwnProperty.call(_state.filters, key)) {
                    if (Array.isArray(_state.filters[key])) {
                        for (const f of _state.filters[key]) {
                            params.append(key + "[]", f);
                        }
                    } else {
                        params.append(key + "_like", _state.filters[key]);
                    }
                }
            }

            try {
                let { data, filterLength, totalLength } = await _mainDS.getItems(params);
                _count_filtered = filterLength;
                _count_all = totalLength;
                _pageItems = data;
            } catch (e) {
                _options.errorFn(e)
            }

        } else {
            try {
                _items = await _mainDS.getItems();
                _displayItems = _items;
                if (_options.filterRow) {
                    _displayItems = filterItems.filter(_displayItems, _state.filters, _columnsObj);
                }

                _displayItems.sort(sort.fn(_state.sorts, _columnsObj, self.sortFn));

                _pageItems = _displayItems;
                _count_filtered = _displayItems.length, _count_all = _items.length;
                if (_options.pagination.enable) {
                    let maxPage = Math.ceil(_pageItems.length / _options.pagination.length);
                    if (_state.currentPage > maxPage) _state.currentPage = maxPage - 1;
                    _pageItems = _pageItems.slice(_first_in_page, _last_in_page);
                }
            } catch (e) {
                _options.errorFn(e)
            }
        }

        // Update iconos sort
        _options.columns.forEach(column => {
            let orderClass = "";
            //Busco esta columna en el array de orden
            for (const s of _state.sorts) {
                if (s.column == column.dataField) {
                    orderClass = "sort_ " + s.order;
                    break;
                }
            }

            $("th[data-datafield='" + column.dataField + "']", _container)
                .attr("hidden", !column.showInTable)
                .removeClass("sort_ asc desc")
                .addClass(orderClass);
        });

        tbody.html("");

        //dibujo de cada celda
        _pageItems.forEach(row => {
            const keyColumn = _mainDS.keyColumn
            let tr = $("<tr>")
                .attr("data-id", row[keyColumn])
                .appendTo(tbody);
            _options.columns.forEach(column => {
                let td = $("<td>")
                    .attr("hidden", !column.showInTable)
                    .attr("data-id", row[keyColumn])
                    .appendTo(tr);
                if (column.template) {
                    column.template(td, row, column);
                } else {
                    cellTemplater(td, row, column, _options.getFilesUrl);
                }

                if (column.onClick) {
                    td.on('click', function (e) {
                        column.onClick.call(this, e, row)
                    });
                }

                if (column.onDblClick) {
                    td.on('dblclick', function (e) {
                        column.onDblClick.call(this, e, row)
                    });
                }

            })

            if (_options.editing.canEdit || _options.editing.canShow || _options.editing.canDelete || _options.editing.extraButtonsTemplate) {
                let td = $(`<td class='commands'>`).appendTo(tr);
                if (typeof _options.editing.canEdit == 'function' ? _options.editing.canEdit(row) : _options.editing.canEdit) {
                    $(`<button  title='${i18n.t('edit')}' data-id='${row[keyColumn]}' class='edit_btn btn'>${_options.class.editButton}</button>`).appendTo(td);
                }

                if (typeof _options.editing.canShow == 'function' ? _options.editing.canShow(row) : _options.editing.canShow) {
                    $(`<button title='${i18n.t('show')}' data-id='${row[keyColumn]}' class='show_btn btn'>${_options.class.showButton}</button>`).appendTo(td);
                }

                if (typeof _options.editing.canDelete == 'function' ? _options.editing.canDelete(row) : _options.editing.canDelete) {
                    $(`<button title='${i18n.t('delete')}' data-id='${row[keyColumn]}' class='delete_btn btn'>${_options.class.deleteButton}</button>`).appendTo(td);
                }
                if (_options.editing.extraButtonsTemplate) {
                    _options.editing.extraButtonsTemplate(td, row);
                }
                td.attr('data-id', row[keyColumn]);
            }
        });

        if (_pageItems.length == 0) {
            tbody.html(`<tr><td class="text-center" colspan="${columnsQuantity}">${i18n.t('there_is_no_data')}</td></tr>`);
        }

        tbody.off("click", ".edit_btn").on("click", ".edit_btn", function (e) {
            let row = _mainDS.getItemByKeyColumnSync($(this).data("id"));
            if (_options.editing.actionEdit) {
                _options.editing.actionEdit(row);
                return;
            }
            if (!_options.editing.templateMode) {
                _formBuilder.draw(row, true, 'edit');
            }
            _eventEmitter.emit('showForm', {
                editable: true,
                row: row
            });
        });

        tbody.off("click", ".show_btn").on("click", ".show_btn", function (e) {
            let row = _mainDS.getItemByKeyColumnSync($(this).data("id"));
            if (_options.editing.actionShow) {
                _options.editing.actionShow(row);
                return;
            }
            if (!_options.editing.templateMode) {
                _formBuilder.draw(row, false, 'show');
            }
            _eventEmitter.emit('showForm', {
                editable: false,
                row: row
            });
        });

        tbody.off("click", ".delete_btn").on("click", ".delete_btn", function (e) {
            let row = _mainDS.getItemByKeyColumnSync($(this).data("id"));
            if (_options.editing.actionDelete) {
                _options.editing.actionDelete(row);
                return;
            }
            //Muestro confirmación
            _options.confirmFn(i18n.t("Are you sure to delete this entity?")).then(async (value) => {
                if (value) {
                    try {
                        await _mainDS.delete(row[_mainDS.keyColumn]);
                    } catch (e) {
                        _options.errorFn(e);
                        /**
                         * deleteError event, se emite cuando hubo un error al eliminar un registro
                         * Se emite junto con el mensaje de error
                         *
                         * @event Tablas#deleteError
                         * 
                         * @type {string}
                         */
                        _eventEmitter.emit('deleteError', e);
                        return;
                    }
                    /**
                     * postDelete event, se emite al eliminar un registro exitosamente
                     *
                     * Se emite junto con el objeto que se elimino
                     * 
                     * @event Tablas#postDelete
                     * 
                     * @type {object}
                     */
                    _eventEmitter.emit('postDelete', row);
                    self.update();
                }
            });
        });

        $(`#${_options.editing.container}`).off("submit", "form").on("submit", "form", async function (e) {
            e.preventDefault();
            e.stopPropagation();
            const btn_text = $(this).find('button:submit').html();
            $(this).find('button:submit').prop("disabled", true);
            // add spinner to button
            $(this).find('button:submit').html(
                `<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> ${i18n.t('Saving...')}`
            );

            let { obj, files } = _formBuilder.serialize();
            const isValid = _formBuilder.validate(_options.validator, obj, files);

            if (!isValid) {
                $(this).find('button:submit').prop("disabled", false);
                $(this).find('button:submit').html(
                    `${btn_text}`
                );
                return;
            }

            /**
             * preSubmit event, se emite antes de enviar el formulario
             * 
             * Se emite junto con la fila que se enviara
             *
             * @event Tablas#preSubmit
             * 
             * @type {object}
             */
            _eventEmitter.emit('preSubmit', obj);

            const { upload } = require("./utils/files");

            try {
                await upload(files, _options.uploadUrl)
            } catch (error) {
                $(this).find('button:submit').prop("disabled", false);
                $(this).find('button:submit').html(
                    `${btn_text}`
                );
                /**
                 * uploadFileError event, se emite cuando hubo un error subiendo los archivos
                 *
                 * Se emite junto con el mensaje de error
                 * 
                 * @event Tablas#uploadFileError
                 * 
                 * @type {string}
                 */
                _eventEmitter.emit('uploadFileError', error);
                _options.errorFn(error);
                return;
            }

            try {
                obj = await _mainDS.save(obj);
            } catch (e) {
                $(this).find('button:submit').prop("disabled", false);
                $(this).find('button:submit').html(
                    `${btn_text}`
                );
                /**
                 * saveError event, se emite cuando hubo un error al enviarse el formulario
                 * 
                 * Se emite junto con el mensaje de error
                 *
                 * @event Tablas#saveError
                 * 
                 * @type {string}
                 */
                _eventEmitter.emit('saveError', e);
                _options.errorFn(e);
                return;
            }
            $(this).find('button:submit').prop("disabled", false);
            $(this).find('button:submit').html(
                `${btn_text}`
            );

            if (!_options.editing.templateMode) {
                _formBuilder.remove();
            }
            /**
             * hideForm event, se emite cuando se solicita ocultar el formulario
             *
             * @event Tablas#hideForm
             * 
             */
            _eventEmitter.emit('hideForm');

            /**
             * postSubmit event, se emite luego de enviarse el formulario exitosamente
             *
             * Se emite junto con el objeto que devolvio el server
             * 
             * @event Tablas#postSubmit
             * 
             * @type {object}
             */
            _eventEmitter.emit('postSubmit', obj);

            self.update();
        })

        if (_options.pagination.enable) {
            self.drawPaginationBar();
        }

        // Avisamos a los plugines que se actualizo la tabla
        for (const pl of _options.plugins) {
            pl.onUpdate(_container);
        }

        /**
         * postUpdate event, se emite luego de actualizar el cuerpo de la tabla
         *
         * @event Tablas#postUpdate
         * 
         */
        _eventEmitter.emit('postUpdate');
    }

    /**
     * drawPaginationBar dibuja la barra de paginación.
     */
    this.drawPaginationBar = function () {
        let is_first_page = _first_in_page == 0;
        let is_last_page = Math.min(_last_in_page, _count_filtered) == _count_filtered;

        $(".pagination-buttons", '#' + _options.container).html($(`
            <button class="pagination-first btn btn-r2 mr-2" ${is_first_page ? 'disabled' : ''} ><i class="icon-buttons fas fa-angle-double-left"></i> <span class='button-span'>${i18n.t('first_page')}</span></button>
            <button class="pagination-prev btn btn-r2"  ${is_first_page ? 'disabled' : ''} ><i class="icon-buttons fas fa-angle-left"></i> <span class='button-span'>${i18n.t('previous_page')}</span></button>
            <span class="pagination-indicator mr-4 ml-4">${_count_filtered == 0 ? 0 : _first_in_page + 1} - ${Math.min(_last_in_page, _count_filtered)} / ${_count_filtered} (${_count_all}) </span>
            <button class="pagination-next btn btn-r2 mr-2" ${is_last_page ? 'disabled' : ''} ><span class='button-span'>${i18n.t('next_page')}</span> <i class="icon-buttons fas fa-angle-right"></i></button>
            <button class="pagination-last btn btn-r2" ${is_last_page ? 'disabled' : ''} ><span class='button-span'>${i18n.t('last_page')}</span> <i class="icon-buttons fas fa-angle-double-right"></i></button>
        `));

        $('.pagination-buttons', '#' + _options.container)
            .off("click", ".pagination-first")
            .on("click", ".pagination-first", function (e) {
                if (_state.currentPage <= 0) {
                    return;
                }
                _state.currentPage = 0;
                self.update();
            });

        $('.pagination-buttons', '#' + _options.container)
            .off("click", ".pagination-prev")
            .on("click", ".pagination-prev", function (e) {
                if (_state.currentPage <= 0) {
                    return;
                }
                _state.currentPage = Math.max(_state.currentPage - 1, 0);
                self.update();
            });

        $('.pagination-buttons', '#' + _options.container)
            .off("click", ".pagination-next")
            .on("click", ".pagination-next", function (e) {
                if (_state.currentPage >= Math.ceil(_count_filtered / _options.pagination.length) - 1) {
                    return;
                }
                _state.currentPage = Math.min(_state.currentPage + 1, Math.ceil(_count_filtered / _options.pagination.length) - 1);
                self.update();
            });

        $('.pagination-buttons', '#' + _options.container)
            .off("click", ".pagination-last")
            .on("click", ".pagination-last", function (e) {
                if (_state.currentPage >= Math.ceil(_count_filtered / _options.pagination.length) - 1) {
                    return;
                }
                _state.currentPage = Math.ceil(_count_filtered / _options.pagination.length) - 1;
                self.update();
            });
    }

    /**
     * Recarga solo el tbody de tabla.
     * Fuerza la solicitud de los datos al servidor.
     */

    this.reload = function () {
        _mainDS.reload();
        self.update();
    }

    /**
     * Agrega la clase active al tr cuyo id coincide con el parametro
     * @param {int} id identificador de la fila que se quiere seleccionar
     */

    this.selectRowById = function (id) {
        this.deselectAllRows();

    }

    /**
     * Quita la clase active a todos los tr de la tabla
     * 
     */

    this.deselectAllRows = function () {
        let tr = $('#' + _options.container + ' table tbody tr');
        for (var i = 0; i < tr.length; i++) {
            $(tr[i]).addClass('tablas_active');
            console.log(tr[i]);
        }

    }

    /**
     * Setea el orden por el cual se va a ordenar y luego refresca el contenido de la página.
     */
    this.sort = function (orders) {
        _state.sorts = orders;
        self.update();
    }

    /**
     * Define que función se utilizará para ordenar al llamar a {@link module:tablas~Tablas#sort}
     * @param {*} a primer item a comparar
     * @param {*} b segundo item a comparar
     */
    this.sortFn = function (a, b) {
        return naturalCompare(a, b, { caseInsensitive: true });
    }

    /**
     * Setea la pagina (hace un update)
     * @param {int} n nro de pagina 0-based
     */
    this.setPage = function (n) {
        if (n < 0) _state.currentPage = 0;
        else if (n > Math.ceil(_displayItems.length / _options.pagination.length) - 1) _state.currentPage = Math.ceil(_displayItems.length / _options.pagination.length) - 1;
        else _state.currentPage = n;
        self.update();
    }

    /**
     * Setea la cantidad de elementos que se muestran en una página.
     * 
     * @param {int} n nro. de elementos en la página
     */
    this.setPageLength = function (n) {
        if (n >= 0) {
            _options.pagination.length = n;
            self.update();
        }
    }

    /**
     * Vincula una función a un evento
     * 
     * @param {string} eventName nombre del evento
     * @param {function} listener función asociada al evento
     */
    this.on = function (eventName, listener) {
        _eventEmitter.on(eventName, listener);
    }

    /**
     * Desvincula una función a un evento
     * 
     * @param {string} eventName nombre del evento
     * @param {function} listener función asociada al evento
     */
    this.off = function (eventName, listener) {
        _eventEmitter.off(eventName, listener);
    }

    init.init();
}

/**
 * Esta funcion permite setear el locale por fuera de lo que diga el navegador
 * 
 * @static
 */
Tablas.setLocale = i18n.setLocale;

module.exports = Tablas;