/**
 * Función que realiza pedidos por API.
 * 
 * Utiliza fetch
 * 
 * @module api_request
 */


/**
 * api_request
 * 
 * @param {string} method GET, PUT, POST, etc
 * @param {string} url url a donde hacer el request
 * @param {object} data objecto a enviar en el request, si no es del tipo formdata se envia como json o en la url si el method es GET.<br>
 * Tipos validos para data:
 * <ul>
 *   <li>Metodo GET:
 *    <ul>
 *      <li>URLSearchParams: Se convierte a string y se agrega a la url</li>
 *      <li>Otros: Un valor valido para pasarle al constructor de URLSearchParams
 *    </ul>
 *   </li>
 *   <li>Otros Metodos:
 *     <ul>
 *       <li>FormData: Se pasa directo al body</li>
 *       <li>Otros: se pasa por JSON.strigify() y se pasa al body con los headers:<br>
 *         <code>
 *         'Accept': 'application/json',<br>
 *         'Content-Type': 'application/json'
 *         </code>
 *       </li>
 *     </ul>
 *   </li>
 * </ul>
 * @returns {Promise} La promesa de fetch
 * @see {@link https://developer.mozilla.org/es/docs/Web/API/URLSearchParams/URLSearchParams|URLSearchParams}
 */
module.exports = function (method, url, data) {
    let body = null;
    let headers = {};
    let new_url = url;
    if (method == "GET" && data instanceof URLSearchParams) {
        new_url += '?' + data.toString();
    } else if (method == "GET") {
        let params = (new URLSearchParams(data)).toString();
        if (params) {
            new_url += '?' + params;
        }
    } else if (data instanceof FormData) {
        body = data
    } else {
        body = JSON.stringify(data)
        headers = {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        };
    }
    const token = localStorage.getItem('mk_token')
    if (token) {
        headers['Authentication'] = `Bearer ${token}`
    }
    const config = {
        method: method,
        body: body,
        headers
    }
    return fetch(new_url, config)
        .then(response => {
            if (response.ok) {
                return response.json();
            } else if (response.status == 401) {
                location.reload();
            } else {
                return response.json().then(t => {
                    throw t.error
                });
            }
        })
        .catch(res => {
            throw res;
        });
}