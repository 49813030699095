const en = require('../locales/en.json');
const es = require('../locales/es.json');
const moment = require("moment");

/**
 * Funciones utilitarias para descargar y subir archivos.
 * @module translate
 */

const localeSupported = {
    'en': en,
    'es': es,
    'en-US': en
};

const fullyQualifiedLocaleDefaults = {
    en: "en-US",
    es: "es-AR",
};

const defaultLocale = en;

// The active locale
let locale;

// Gets filled with active locale translations
let translations = {};

/**
 * Traduce la string que se le pasa como argumento usando la locale definida
 * anteriormente (por defecto la del navegador).
 * 
 * @param {string} key - palabra que se quiere traducir
 * @param {*} interpolations - argumentos para el lenguaje de template ({})
 * @returns string
 */
function t(key, interpolations = {}) {
    if (!translations[key]) {
        return interpolate('@' + key, interpolations);
    }
    return interpolate(translations[key], interpolations);
}

// Convert a message like "Hello, {name}" to "Hello, Chad"
// given the interpolations object {name: "Chad"}
function interpolate(message, interpolations) {
    return Object.keys(interpolations).reduce(
        (interpolated, key) =>
            interpolated.replace(
                new RegExp(`{\s*${key}\s*}`, "g"),
                interpolations[key],
            ),
        message,
    );
}
/**
 * Setea la locale a utilizar, por defecto se utiliza la locale del navegador
 * 
 * @param {string} newLocale - locale a utilizar
 */
function setLocale(newLocale) {
    if (newLocale === locale) return;
    if (isSupported(newLocale)) {
        locale = newLocale;
        translations = localeSupported[newLocale];
        moment.locale(newLocale);
    }
}


function isSupported(locale) {
    return localeSupported[locale];
}


// Retrieve the first locale we support from the given
// array, or return our default locale
function supportedOrDefault(locales) {
    return locales.find(isSupported) || defaultLocale;
}

/**
 * Retrieve user-preferred locales from the browser
 *
 * @param {boolean} languageCodeOnly - when true, returns
 * ["en", "fr"] instead of ["en-US", "fr-FR"]
 * @returns array | undefined
 */
function browserLocales(languageCodeOnly = false) {
    return navigator.languages.map((locale) =>
        languageCodeOnly ? locale.split("-")[0] : locale,
    );
}

/**
 * Formatea un numero segun la locale definida en el navegador (exclusivamente)
 * 
 * @param {Number} number - numero a formatear
 * @param {Object} options - opciones que se le pasan a toLocaleString
 * @returns string
 */
function formatFloat(number, options) {
    if (!options) {
        options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    }
    return number.toLocaleString(undefined, options);
}

/**
 * Formatea la fecha segun la locale definida en el navegador (exclusivamente)
 * 
 * @param {string} date - fecha en formato compatible con moment.
 * @returns string
 */
function formatDate(date) {
    return moment(date).format("L");
}

/**
 * Formatea el timestamp segun la locale definida en el navegador (exclusivamente)
 * 
 *  
 * @param {string} datetime - timestamps en formato compatible con moment
 * @returns string
 */
function formatDateTime(datetime) {
    return moment(datetime).format("L LTS");
}

setLocale(supportedOrDefault(browserLocales(true)));

module.exports = { t, setLocale, formatDate, formatFloat, formatDateTime }
