const naturalCompare = require('string-natural-compare');
const i18n = require('./utils/translate');

/**
 * Modulo que define como se muestran los datos en las celdas de la tabla
 * @module cellTemplater
 */

/**
 * Funcion que se encarga de dibujar el contenido de una celda de la tabla.
 * 
 * Utiliza el dataType de la definicion de la columna o la funcion calculateDisplayValue.
 * Si la columna tiene un dataSource asociado utiliza calculateDisplayValue del dataSource.
 * 
 * @param {$HTMLElement} td Celda de la tabla donde se insertan los datos
 * @param {Object} row Fila de la tabla con los datos.
 * @param {ColumnDef} column Definicion de la columna.
 * @param {string} getFilesUrl Url donde pedir archivos si fuera necesario.
 */
module.exports = function (td, row, column, getFilesUrl) {
    if (column.class) {
        td.addClass(column.class)
    }
    td.addClass('tablas-cells')

    if (!column.dataSource) {
        let content = row[column.dataField];

        if (column.calculateDisplayValue) {
            content = column.calculateDisplayValue(row);
        } else if (!(column.dataField in row) || content == null) {
            return;
        }

        switch (column.dataType) {
            case "float":
                content = i18n.formatFloat(+content);
            case "integer":
                td.addClass("text-right");
                td.attr('title', content);
                break
            case "date":
                content = i18n.formatDate(content);
                td.attr('title', content);
                break;
            case "timestamp":
                content = i18n.formatDateTime(content);
                td.attr('title', content);
                break;
            case "color":
                td.attr('title', content);
                content = `<div><div class="color-pick" style="background-color: ${content}"></div> ${content}</div>`;
                break;
            case "boolean":
                td.attr('title', i18n.t(content));
                content = content ? "<div class='text-center symbol_true'>✓</div>" : "<div class='text-center symbol_false'>×</div>";
                break;
            case "file":
                let t = "";
                if (Array.isArray(content)) {
                    t = content.map((x) => {
                        let type = 'fa-file';
                        if (x.type.match(/image/)) {
                            type = 'fa-file-image';
                        }
                        return `<a title="${x.originalname}" class="btn btn-xs" target="_blank" href="${getFilesUrl}${x.filename}"><i class="far ${type}"></i></a>`;
                    }).join("");
                    td.attr('title', `${content.length} ${i18n.t('files')}`);
                } else {
                    let type = 'fa-file';
                    if (content.type.match(/image/)) {
                        type = 'fa-file-image';
                    }
                    t = `<a class="btn btn-xs" target="_blank" href="${getFilesUrl}${content.filename}"><i class="far ${type}"></i></a>`;
                    td.attr('title', content.originalname);
                }
                content = t;
                break;
            default:
                td.attr('title', content);
        }

        if (column.title) {
            td.attr('title', column.title(row));
        }
        td.addClass('tablas-cells');

        td.html(content).attr("data-id", row.id)
    } else {
        if (!row[column.dataField]) {
            return;
        }
        if (column.multiple) {
            let value = [];

            for (const x of row[column.dataField]) {
                item = column._dataSource.getItemByKeyColumnSync(x);

                let display = item[column._dataSource.displayColumn];
                if (column._dataSource.calculateDisplayValue) {
                    display = column._dataSource.calculateDisplayValue(item);
                }

                value.push(display);
            }

            value.sort((a, b) => naturalCompare(a, b, { caseInsensitive: true }));

            td.html(value.join(", "))
                .attr("data-id", row.id)
                .attr('title', value.join(", "));
        } else {
            let item = column._dataSource.getItemByKeyColumnSync(row[column.dataField]);

            if (!item) {
                console.error(`Value ${row[column.dataField]} (row: ${row["id"]}) not found on dataSource of: ${column.dataField}`);
            } else {
                let display = item[column._dataSource.displayColumn];
                if (column._dataSource.calculateDisplayValue) {
                    display = column._dataSource.calculateDisplayValue(item);
                }

                td.html(display)
                    .attr("data-id", row.id)
                    .attr('title', display);
            }
        }
    }
}