let i18n = require('./utils/translate');

/**
 *  @module filterItems
 */

/**
 * Filtra el array de entrada a partir de los filtros dados y la definicion de las columnas.
 * 
 * @param {Array<Object>} items Array de items donde buscar.
 * @param {Object.<string, string>} filters Diccionario dataField -> string a buscar
 * @param {Object.<string, module:tablas~ColumnDef>} columnsObj  Diccionario dataField -> objecto de definicion de columna.
 * @returns {Array<Object>}
 */
function filter(items, filters, columnsObj) {
    let ret = [];
    for (const item of items) {
        let accepted = true;

        for (const key in filters) {
            if (Object.hasOwnProperty.call(filters, key)) {
                if (!(key in columnsObj)) {
                    console.error("La columna key no existe (puede ser que se haya reutilizado el nombre de un container?)")
                }
                const valtofilter = filters[key];
                if ((!(key in item) || item[key] == null) && !columnsObj[key].calculateDisplayValue) {
                    accepted = false;
                    break;
                }
                if (columnsObj[key].dataSource) {
                    if (Array.isArray(item[key])) {
                        accepted = false;
                        for (const y of item[key]) {
                            accepted = valtofilter == y;
                            if (accepted) break;
                        }
                    } else {
                        accepted = valtofilter == (item[key].toString());
                    }
                } else {
                    let value = item[key];
                    if (columnsObj[key].calculateDisplayValue) {
                        value = columnsObj[key].calculateDisplayValue(item);
                    } else {
                        switch (columnsObj[key].dataType) {
                            case "float":
                                value = i18n.formatFloat(value);
                                break
                            case "date":
                                value = i18n.formatDate(value);
                                break;
                            case "timestamp":
                                value = i18n.formatDateTime(value);
                                break;
                            case "file":
                                let val = [];
                                if (Array.isArray(value)) {
                                    value.forEach(element => {
                                        val.push(element.filename.toLowerCase());
                                        val.push(element.originalname.toLowerCase());
                                    });
                                } else {
                                    val.push(value.originalname.toLowerCase());
                                    val.push(value.filename.toLowerCase());
                                }
                                value = val.join(", ");
                                break;
                        }
                    }
                    if (columnsObj[key].dataType == 'boolean') {
                        accepted = valtofilter.includes(value.toString());
                    } else {
                        accepted = value.toString().toLowerCase().indexOf(valtofilter.toLowerCase()) >= 0;
                    }
                }
                if (!accepted) break;
            }
        }

        if (accepted) {
            ret.push(item);
        }
    }

    return ret;
}

module.exports = { filter };